import React from "react";
import Layout from "../../../../components/Layout/layout";
import WhyTaxiBlog from "../../../../components/Blog-Detail/whyTaxi/index";
import SEO from "../../../../components/SEO/seo";
const WhyTaxi = () => {
	return (
		<Layout>
			<SEO
				title="Why: Taxi from Hamburg Airport to City Centre"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Considering a taxi from the airport is one of the best option for commuters these days."
			/>
			<WhyTaxiBlog />
		</Layout>
	);
};

export default WhyTaxi;
